<div class="container">
  <button (click)="goToAddPhotos()" class="btn btn-danger col-12">Ajouter photos pour sites touristiques</button>
  <form style="background-image: url('assets/img/bg.jpg'); padding: 10px;">
    <div class="form-row">
      <div class="col-10"></div>
      <div class="col-2"><button class="btn btn-danger" (click)="logout()"><i class="fa fa-sign-out fa-2x" aria-hidden="true"></i></button></div>
    </div>
    <div class="form-group">
      <label class="h3">Nom</label>
      <input type="text" class="form-control" name="titre" [(ngModel)]="site.nom" placeholder="Titre">
    </div>
    <div class="form-row">
    <div class="form-group col-6">
      <label class="h3">Catégorie:</label>
      <select class="form-control" name="cat" [(ngModel)]="site.categorie">
        <option value="Monument">Monument</option>
        <option value="Jardin">Jardin</option>
        <option value="Musée">Musée</option>
          </select>
    </div>
  </div>

  <div class="form-group">
    <div class="form-group col-6">
    <label class="h3">Coordonnées X (Longitude):</label>
    <input type="text" class="form-control" name="xloc" [(ngModel)]="site.xloc" placeholder="x">
  </div>
  <div class="form-group col-6">
    <label class="h3">Coordonnées Y (Latitude):</label>
    <input type="text" class="form-control" name="yloc" [(ngModel)]="site.yloc" placeholder="y">
  </div>
  </div>

    <div class="form-group">
      <label class="h3">Description</label>
      <editor name="dd" [(ngModel)]="site.description"
      apiKey="nxugqykf0aggo3yb2jkeack5ozx7fi7d0xwbgw6rjg9ygg97"
   [init]="{
     height: 300,
     menubar: ['table','insert'],
     plugins: [
     'advlist autolink lists link image charmap print',
     'preview anchor searchreplace visualblocks code',
     'fullscreen insertdatetime media table paste',
     'help wordcount','link','table'
     ],
     toolbar:
     'undo redo | formatselect | bold italic |fontsizeselect| \
     alignleft aligncenter alignright alignjustify | \
     bullist numlist outdent indent|forecolor backcolor |link| table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol|help'
   }"
 ></editor>
  </div>

<div class="form-group col-6 offset-3">
  <button class="btn btn-info col-12 " (click)="addSite()">Ajouter</button>
</div>
  </form>
<div class="col-12 h2">{{_allsite?.length}} sites touristiques</div>
<div *ngFor="let site of _allsite; let i=index" class="col-12">
  <table class="table table-bordered" style="font-size: 14pt;">
    <thead>
      <tr style="background-color:rgb(7, 40, 129) ; color: aliceblue; font-size: 14pt;">
        <th scope="col">#</th>
        <th scope="col">Site</th>
        <th scope="col">Catégorie</th>

      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row">{{i+1}}</th>
        <td>{{site.nom}}</td>
        <td>{{site.categorie}}</td>

      </tr>
      <tr>
        <th scope="row">Description</th>
        <td style="text-align:justify ;" colspan="3" [innerHTML]="site.description"></td>
      </tr>

      <tr>

        <td colspan="4" *ngIf="_pohotos[site.id].length"><!--afficher si le tableau _pohotos[site.id] non vide -->
          <img style="margin-right:5px ; width:100px ; height:75px ;"  *ngFor="let pic of _pohotos[site.id]" class="img-fluid" src="{{srcimg}}{{pic.nomphoto}}" alt="">
        </td>
      </tr>

      <tr>

        <td colspan="4" *ngIf="site.xloc"><!--afficher si le tableau _pohotos[site.id] non vide -->
          X: {{site.xloc}}; &nbsp; &nbsp; Y: {{site.yloc}}
        </td>
      </tr>


      <tr>
        <td colspan="4"> <button data-toggle="modal" data-target="#suppConfirm" (click)="getIdToDelete(site.id)" class="btn btn-danger"><i class="fa fa-trash-o fa-2x" aria-hidden="true"></i></button></td>
      </tr>

    </tbody>
  </table>
  <hr class="hr-habib">
</div>


<!---------------Modal suppression------------>
  <!-- The Modal -->
  <div class="modal" id="suppConfirm">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Suppression</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Voulez-vous vraiment supprimer ce site?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="supprimerSite()" data-dismiss="modal">Ok</button>
          <button type="button" class="btn btn-secondary"  data-dismiss="modal">Annuler</button>
        </div>
      </div>
    </div>
  </div>
  <!---------------Fin modal supp-------------->

</div>
