import { Site } from './../models/site';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HostService } from './host.service';

@Injectable({
  providedIn: 'root'
})
export class SiteService {
  headers: HttpHeaders;
  constructor(private _http:HttpClient, private hostservice:HostService) {
    const token = localStorage.getItem('token');
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
  }
  host = this.hostservice.myhost;
  //-------------------Ajout-------------------
  siteUrl = this.host+"api/sites";
  setNewSite(site:Site){
    return this._http.post(this.siteUrl,site,{ headers: this.headers });
  }
  //-------------------------------------------

  urlsite =  this.host+"api/sites";
  getAllSite(){
    return this._http.get<Site[]>(this.urlsite);
  }
//-------------------------------------------

urlsite_with_pics =  this.host+"api/siteswithpics";
getAllSiteWithPics(){
  return this._http.get<any[]>(this.urlsite_with_pics);
}


//-------------------Suppression-------------------
delSiteUrl = this.host+"api/sites/";
deletSite(id:number){
  return this._http.delete(this.delSiteUrl+id,{ headers: this.headers });
}

  //----------------------------------

}
