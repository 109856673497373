import { Photo } from './../models/photo';
import { Site } from 'src/app/models/site';
import { HostService } from './host.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PhotoService {
  headers: HttpHeaders;
  constructor(private _http:HttpClient, private hostservice:HostService) {
    const token = localStorage.getItem('token');
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
   }
  host = this.hostservice.myhost;
  //----------------------uplodImageAvis---------------
  urlup =  this.host+"api/photos";
  uploadSiteImage(fd:FormData){
    return this._http.post(this.urlup,fd,{ headers: this.headers });
  }
  //----------------------------------
  urlsite =  this.host+"api/sites";
  getAllSite(){
    return this._http.get<Site[]>(this.urlsite);
  }

  //----------------------------------
}
