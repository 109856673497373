<form style="background-image: url('assets/img/bg.jpg'); padding: 10px;">
  <div class="form-row">
    <div class="form-group col-6">
      <label class="h3">Catégorie:</label>
      <select class="form-control" name="cat" [(ngModel)]="site_id">
        <option *ngFor="let site of allsite" value="{{site.id}}">{{site.nom}}</option>
      </select>
    </div>
  </div>

  <div class="form-group">
    <div class="custom-file">
      <input type="file" (change)="onFileSelected($event)" class="custom-file-input" id="customFile" name="imageFile">
      <label class="custom-file-label" style="background: rgb(140, 77, 153); color: beige;" for="customFile">{{photoname}}</label>
    </div>
  </div>

  <div class="form-group">
    <button class="btn btn-success" (click)="addPhoto()"> Ajouter la photo au site</button>
  </div>
</form>
