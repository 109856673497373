import { Photo } from './../../models/photo';
import { PhotoService } from './../../services/photo.service';
import { SiteService } from './../../services/site.service';
import { Router } from '@angular/router';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Site } from 'src/app/models/site';
import { HostService } from 'src/app/services/host.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-cp',
  templateUrl: './cp.component.html',
  styleUrls: ['./cp.component.css']
})
export class CpComponent implements OnInit {

  constructor(private auth:AuthService,
    private service_site:SiteService,
    private ps:PhotoService,
    private hostservice:HostService,
    private sanitizer: DomSanitizer,
     private router:Router) { }

  _allsite:Site[];
  _pohotos:any;

  myMap = new Map<number, Photo[]>();
  srcimg="";
  ngOnInit(): void {
    this.allSites();
    this.srcimg = this.hostservice.myhost+"storage/photos/";
  }
site = new Site();
//-------------get all site
allSites(){
  this.service_site.getAllSiteWithPics().subscribe(
    data =>{
      this._allsite=data[0];
      this._allsite.map(el => {el.description = this.sanitizer.bypassSecurityTrustHtml(el.description)})
      this._pohotos = data[1] } ,
    err => console.error(err),
   // ()=>console.log(this._pohotos[57]),
  );
}

//---------add------------------
addSite(){
this.service_site.setNewSite(this.site).subscribe(
  () => { this.allSites()},
  err => console.error(err),
)
}

//------------aller vers add photos
goToAddPhotos(){
  this.router.navigate(['/addphotos']);
}
  //---------------------Logout---------
logout(){
  localStorage.setItem('token','');
  localStorage.setItem('nom','');
  this.auth.signOut();
  this.router.navigate(['/login']);
}
//-------------------------------------
idDelete:number;
getIdToDelete(id:number){
this.idDelete = id;
}
supprimerSite(){
this.service_site.deletSite(this.idDelete).subscribe(
  data => this.allSites(),
  err => console.error(),
  ()=> console.log('ok'),
)
}

///////------------------------------

}
