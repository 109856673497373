import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PhotosGuard implements CanActivate {
  constructor(private auth:AuthService, private router:Router){}
  canActivate():boolean{
      if(this.auth.isLoggedIn){

        //console.log('guard:',this.auth.isLoggedIn);
        return true;
      }else{
        //console.log('guard:',this.auth.isLoggedIn);
        this.router.navigate(['/login']);
        return false;
      }
    }


}
