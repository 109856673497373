import { Site } from './../../models/site';
import { PhotoService } from './../../services/photo.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.css']
})
export class PhotosComponent implements OnInit {

  constructor(private ps:PhotoService) { }
  photoname="Choisir une image";
  site_id = 57;
  selectedFile = null;
  allsite:Site[];
  ngOnInit(): void {
    this.ps.getAllSite().subscribe(
      data => this.allsite=data,
      err => console.error(err),
      ()=>console.log('ok'),
    );
  }
  //------------------------------------------


  onFileSelected(event){
    this.selectedFile = event.target.files[0];
    this.photoname = this.selectedFile.name;
    //console.log(this.selectedFile);
  }
  //-----------------Addd Photo-----------------
  addPhoto(){
    if(this.selectedFile!=null){
      let fd = new FormData();
      fd.append('photo_site',this.selectedFile,this.selectedFile.name);
      fd.append('nomphoto',"");
      fd.append('site_id',String(this.site_id))
      this.ps.uploadSiteImage(fd).subscribe(
        //res => console.log(res),
        err => console.error(err)
      );
      this.selectedFile = null; // pour vider après l'envoie du fichier
      this.photoname="Choisir une image"; //bouton select file
    }
  }

  //--------------------------------------------------------
}
