import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HostService {

  constructor() { }
  //myhost = "http://127.0.0.1:8000/";
  myhost = "https://alkhawarizmi.recherche-scientifique-flshm.ma/";
}
