import { PhotosGuard } from './guards/photos.guard';
import { PhotosComponent } from './adm/photos/photos.component';
import { LoginComponent } from './adm/login/login.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CpComponent } from './adm/cp/cp.component';
import { CpGuard } from './guards/cp.guard';

const routes: Routes = [
  {path:'cp',component:CpComponent,canActivate:[CpGuard]},
  {path:'addphotos',component:PhotosComponent,canActivate:[PhotosGuard]},
  {path:'login',component:LoginComponent},
  {path:'',redirectTo:'/login',pathMatch:'full'},
  {path:'**',redirectTo:'/login'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
